import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { About } from "./components/About";
import { PostList } from "./components/PostList";
import { PostDetail } from "./components/PostDetail";
import { Footer } from "./components/Footer";

export default function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <header>
          <NavBar />
        </header>
        <main className="flex-grow text-gray-300 bg-gray-900 body-font">
          <Routes>
            <Route path="/blog" element={<PostList />} />
            <Route path="/blog/:id" element={<PostDetail />} />
            <Route
              path="/"
              element={<About />}
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}
