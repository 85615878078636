import React from "react";

export function Footer() {
  return (
    <footer className="bg-gray-800 w-full">
      <div className="container mx-auto flex px-10 py-4">
        <div className="text-xs text-gray-400">
          <h5>mmxxv jacob marthaller</h5>
        </div>
      </div>
    </footer>
  );
}
