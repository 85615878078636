import React, { useState } from "react";
import { MailIcon } from "@heroicons/react/solid";
import emailjs from "emailjs-com";

export function About() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(""); // For feedback to the user

  async function handleSubmit(e) {
    e.preventDefault();
    const templateParams = {
      name: name,
      email: email,
      message: message,
    };
    try {
      await emailjs.send(
        "service_emeb2vk",
        "contact_form",
        templateParams,
        "user_OgPNXecgtK66tUJbljrqL"
      );
      setStatus("Message sent successfully!");
    } catch (error) {
      setStatus("An error occurred. Please try again.");
    } finally {
      e.target.reset();
      setName("");
      setEmail("");
      setMessage("");
    }
  }

  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center mb-28">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            Hi, I'm Jacob
          </h1>
          <p className="mb-7 leading-relaxed">
            I'm a Full Stack Software Engineer working primarily in C#, TypeScript,
            and Ruby. I love building software to solve hard problems.
            <br />
            <br />
            I also love writing. I've written about lots of things in the past, including
            <a href="https://www.tandfonline.com/doi/full/10.1179/1462317X15Z.000000000146" style={{ fontWeight: 'bold' }}> open source software</a>, <a href="https://search.lib.virginia.edu/sources/uva_library/items/2514nk71t" style={{ fontWeight: 'bold' }}>ethics</a>, and <a href="https://www.washingtonpost.com/news/acts-of-faith/wp/2017/03/23/trumps-new-drone-policy-might-violate-centuries-of-christian-ethics/" style={{ fontWeight: 'bold' }}>drones</a>, among other things. I expect to use 
            this site to write about software engineering, or whatever else I'm thinking a lot about.
          </p>
          <div className="flex justify-center mb-8 space-x-4">
  <a
    href="https://www.linkedin.com/in/jacob-marthaller/"
    className="inline-flex items-center"
  >
    <img
      align="left"
      alt="linkedin-logo"
      src="https://icongr.am/fontawesome/linkedin-square.svg?size=128&color=a0a0a0"
      height="30"
      width="30"
    />
  </a>
  <a
    href="https://github.com/jmarthaller"
    className="inline-flex items-center"
  >
    <img
      align="left"
      alt="github-logo"
      src="https://icongr.am/fontawesome/github.svg?size=128&color=a0a0a0"
      height="30"
      width="30"
    />
  </a>
  <a
    href="https://x.com/marthallerj"
    className="inline-flex items-center"
    aria-label="X-page"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="30"
      height="30"
      fill="#a0a0a0"
      className="octicon"
    >
      <path
        fill="currentColor"
        d="M9.332 6.925 14.544 1h-1.235L8.783 6.145 5.17 1H1l5.466 7.78L1 14.993h1.235l4.78-5.433 3.816 5.433H15L9.332 6.925ZM7.64 8.848l-.554-.775L2.68 1.91h1.897l3.556 4.975.554.775 4.622 6.466h-1.897L7.64 8.848Z"
      ></path>
    </svg>
  </a>
  <a
    href="https://marthaller-jr.medium.com/"
    className="inline-flex items-center"
  >
    <img
      align="left"
      alt="medium-logo"
      src="https://icongr.am/fontawesome/medium.svg?size=128&color=a0a0a0"
      height="30"
      width="30"
    />
  </a>
</div>

        </div>
        <form
          name="contact"
          onSubmit={handleSubmit}
          className="lg:w-1/3 md:w-1/2 flex flex-col w-full bg-gray-900 p-6 rounded-lg"
        >
          <h2 className="text-white text-3xl mb-4 font-medium title-font">
            Get In Touch{" "}
            <MailIcon className="w-10 inline-block ml-4 mb-1 text-gray-400" />
          </h2>
          {status && (
            <p
              className={`mb-4 ${
                status.includes("successfully")
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {status}
            </p>
          )}
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-400">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-400">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="message" className="leading-7 text-sm text-gray-400">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="text-white bg-twitter-blue border-0 py-2 px-6 focus:outline-none hover:bg-twitter-blue-hover transition delay-100 duration-200 ease-in-out rounded text-lg"
          >
            Send
          </button>
        </form>
      </div>
    </section>
  );
}
