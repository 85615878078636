import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export function PostList() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch("/posts.json");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setPosts(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="container mx-auto py-10 px-4">
        <h1 className="text-2xl font-bold mb-6">Read my writing</h1>
        <ul>
          {posts.map((post) => (
            <li key={post.id} className="mb-4">
              <Link
                to={`/blog/${post.id}`}
                className="text-xl font-semibold hover:underline text-indigo-400"
              >
                {post.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
